import React from 'react';
import { graphql } from 'gatsby';

import { accentColors } from '../config/accent-colors';
import { structuredAddress } from '../config/structured-data-objects';
import { IJobOffer } from '../models/job-offer.model';
import { TAccentColor } from '../models/accent-color.model';
import { useT } from '../hooks/use-translation';
import { getStructuredDataFormattedDate } from '../utils/get-structured-data-formatted-date';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import SectionArticle from '../components/organisms/section-article';

interface IJobOfferPageProps {
    readonly data: {
        jobOffer: IJobOffer;
    };
}

const JobOfferPage: React.FC<IJobOfferPageProps> = ({ data }) => {
    const { t } = useT();
    const { jobOffer } = data;
    const { color, position, content } = jobOffer;

    const structuredData = getJobOfferStructuredData(jobOffer);
    const backgroundColor = accentColors[color] || accentColors.purple;
    const circleColor = circleMatchedColors[color || 'purple'];

    return (
        <MainLayout
            showFloatingCircles={true}
            showContactForm={true}
            contactFormProps={{
                titleProps: { children: t('contact.form.title') },
                description: t('job.contact.form.description'),
                inputContentLabel: t('job.contact.form.content.label'),
                inputFileLabel: t('job.contact.form.file.label'),
                type: 'recruitment',
                showPrivacyInfo: false,
                onBeforeSubmit: (values) => ({ ...values, positionName: position }),
                buttonProps: {
                    color: 'yellow',
                    kind: 'normalDark',
                },
            }}
            structuredData={structuredData}
        >
            <Hero
                titleProps={{ Tag: 'h1', children: position }}
                circleDisplay="color"
                color={circleColor}
                style={{ background: backgroundColor }}
            />
            <SectionArticle>{content}</SectionArticle>
        </MainLayout>
    );
};

const circleMatchedColors: Record<TAccentColor, TAccentColor> = {
    blue: 'pink',
    pink: 'blue',
    purple: 'yellow',
    yellow: 'purple',
};

function getJobOfferStructuredData(jobOffer: IJobOffer) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: jobOffer.position,
        description: jobOffer.content,
        datePosted: getStructuredDataFormattedDate(jobOffer.publishedAt),
        hiringOrganization: {
            '@type': 'Organization',
            name: 'AlterPage Sp. z o.o.',
            sameAs: process.env.BASE_URL,
        },
        jobLocation: {
            '@type': 'Place',
            address: structuredAddress,
        },
    });
}

export const query = graphql`
    query JobOfferPage($locale: String!, $jobOfferId: Int!) {
        jobOffer(locale: { eq: $locale }, jobOfferId: { eq: $jobOfferId }) {
            ...jobOfferFields
        }
    }
`;

export default JobOfferPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
